import { Link } from "@app/components/Link"
import { withSection } from "@app/hoc/Section"
import { useCore } from "@app/hooks/useCore"
import { useImage } from "@app/hooks/useImage"
import { useMedia } from "@app/hooks/useMedia"
import { useRoutes } from "@app/hooks/useRoutes"
import { AspectRatio, Box, GridItem, Image, SimpleGrid, Text, VStack, Heading } from "@chakra-ui/react"
import type { Section } from "@root/types/global"
import React, { memo, useMemo } from "react"

type Props = Section & GatsbyTypes.SanitySectionFeaturedImageText

const FeaturedImageText: React.FC<Props> = withSection(
  memo(({ image: rawImage, imagePlacement, link: rawLink, subtitle: rawSubtitle, title: rawTitle, isHomepage }) => {
    const {
      helpers: { linebreak },
    } = useCore()
    const { getGatsbyImage } = useImage()
    const { isBase } = useMedia()
    const { urlResolver } = useRoutes()

    const image = useMemo(() => getGatsbyImage(rawImage, { width: isBase ? 1000 : 2000 }), [getGatsbyImage, isBase, rawImage])
    const link = useMemo(() => urlResolver(rawLink), [rawLink, urlResolver])
    const subtitle = useMemo(() => linebreak(rawSubtitle), [linebreak, rawSubtitle])
    const title = useMemo(() => linebreak(rawTitle), [linebreak, rawTitle])

    const imageDesktop = getGatsbyImage(image?.desktop, { width: 2000 })
    const imageMobile = getGatsbyImage(image?.mobile?.asset ? image?.mobile : image?.desktop, { width: 1000 })
    const desktopImageWidth = imageDesktop?.dimensions?.width
    const desktopImageHeight = imageDesktop?.dimensions?.height
    const mobileImageWidth = imageMobile?.dimensions?.width
    const mobileImageHeight = imageMobile?.dimensions?.height

    return (
      <Box as="section" py={{ base: 6, md: 8 }}>
        <SimpleGrid columns={{ base: 1, sm: 2 }}>
          <GridItem order={{ base: imagePlacement === "right" ? 1 : 2 }} pos="relative">
            <AspectRatio
              bg="grey.cloud"
              ratio={{
                base: mobileImageWidth / mobileImageHeight || 1,
                sm: desktopImageWidth / desktopImageHeight || 1,
              }}
            >
              <>
                {imageDesktop && (
                  <Image
                    {...imageDesktop}
                    alt={imageDesktop?.alt || title || ""}
                    display={{ base: "none !important", sm: isHomepage ? "none !important" : "flex !important", md: "flex !important" }}
                    maxWidth={{ base: "100%", lg: "100%" }}
                    maxHeight={{ base: "100%", lg: "100%" }}
                  />
                )}
                {imageMobile && (
                  <Image
                    {...imageMobile}
                    alt={imageMobile?.alt || title || ""}
                    display={{ base: "flex !important", sm: isHomepage ? "flex !important" : "none !important", md: "none !important" }}
                    maxWidth={{ base: "100%", lg: "100%" }}
                    maxHeight={{ base: "100%", lg: "100%" }}
                  />
                )}
              </>
            </AspectRatio>
          </GridItem>
          <GridItem
            as={VStack}
            alignItems={{ base: "start", md: "center" }}
            justifyContent={{ base: "start", md: "center" }}
            order={{ base: imagePlacement === "left" ? 2 : 1, md: imagePlacement === "right" ? 1 : 2 }}
            padding={{ base: 6, sm: 10 }}
            pb={{ base: 0 }}
            pr={{ base: 6, md: 4 }}
            pt={{ base: 6, sm: 0 }}
          >
            <VStack alignItems="start" justifyContent="space-between" maxW={{ md: 360 }} spacing={{ base: 4 }} textAlign="start">
              {title && (
                <Heading as="p" size="mainHeading" alignSelf="flex-start">
                  {title}
                </Heading>
              )}
              {subtitle && (
                <Text
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                  color="grey.coal"
                  fontWeight={400}
                  fontSize={14}
                  lineHeight={"17.5px"}
                  letterSpacing={"0.105px"}
                  maxW={{ md: 360 }}
                />
              )}
              {link?.title && (
                <Link
                  to={link?.url}
                  size="underline"
                  variant="underline"
                  title={link.title}
                  aria-label={link.title}
                  color="grey.coal"
                  borderColor="grey.coal"
                  borderBottom="2px"
                  lineHeight={1.5}
                  fontWeight={700}
                  letterSpacing={2.532}
                  fontSize={{ base: 12.66, md: 14 }}
                >
                  {link.title}
                </Link>
              )}
            </VStack>
          </GridItem>
        </SimpleGrid>
      </Box>
    )
  })
)

export default FeaturedImageText
